<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"
			:user="user"

			@click="getSearch()"
			@reset="reset"
			@setSearchDateType="setSearchDateType"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch()"
			>
				<option
					v-for="add in codes.settlement_state"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 box-ddd bg-white full-height flex-column overflow-y-auto">
			<div class="justify-space-between">
				<div>
					<input v-model="item_settlement.calDate" placeholder="정산기준일" class="input-box-inline mr-10 text-center" readonly @click="datePicker = !datePicker"/>

					<input v-model="item_settlement.fromDate" placeholder="매출시작일" class="input-box-inline mr-10 text-center" readonly />

					<input v-model="item_settlement.toDate" placeholder="매출종료일" class="input-box-inline mr-10 text-center" readonly />

					<span class="position-relative">

						<button
							v-if="!item_settlement.calDate"
							class="box mr-10 pa-5-10 size-px-12 bg-identify-outline vertical-middle"
							@click="datePicker = !datePicker"
						>가정산 입력</button>
						<button
							v-else
							class="box mr-10 pa-5-10 size-px-12 bg-identify vertical-middle"
							@click="doSettlement"
						>정산 실행</button>
						<v-date-picker
							v-if="datePicker"
							v-model="item_settlement.calDate"
							no-title
							scrollable
							class="position-absolute"
							style="top: 30px; left: -98px; "
							@change="datePicker = false"
						></v-date-picker>
					</span>
				</div>
				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel2"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">금일 대량 이체파일</span></button>
					<button
						v-if="false"
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>

					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>

					<col width="40px" />
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="180px" />

				</colgroup>
				<thead>
				<tr>
					<th><input v-model="is_all" type="checkbox" > </th>
					<th>NO</th>
					<th>매출 시작일</th>
					<th>매출 종료일</th>
					<th>정산 기준일</th>

					<th>등록일</th>
					<th>등록자</th>
					<th>변경일</th>
					<th>변경자</th>
					<th>정산상태</th>

					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td><input v-model="item.is_select" type="checkbox" > </td>
					<td>{{ item.idx }}</td>
					<td>{{ item.fromDate }}</td>
					<td>{{ item.toDate }}</td>
					<td>{{ item.calDate }}</td>

					<td>{{ item.regDate}}</td>
					<td>{{ item.registrant }}</td>
					<td>{{ item.modiDate }}</td>
					<td>{{ item.modifier }}</td>
					<td>
						<div
							class="box pa-5"
							:class="'bg-' + item.state_color"
						>{{ item.state_name }}</div>
					</td>

					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<div class="mt-10 text-right">
				<button
					class="pa-5-10 mr-10 bg-bbb"
					@click="toConfirm"
				>선택 지급 확정</button>
				<button
					v-if="false"
					class="pa-5-10 mr-10 bg-bbb"
					@click="toCancel"
				>선택 지급 확정 취소</button>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>

		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="is_excel = !is_excel"
		></Excel>

		<Excel
		v-if="is_excel2"
		:excel_data="excel_data2"
		:date="date"

		@finish="is_excel2 = !is_excel2"
	></Excel>


		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			@close="close"
		>
			<div
				slot="modal-bottom"
				class="justify-space-between"
			>
				<button
					class="flex-1 pa-10 bg-identify"
					@click="doSettlement"
				>확인</button>
				<button
					@click="close"
					class="flex-1 pa-10 bg-default"
				>취소</button>
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			:top="true"
			:bottom="false"

			title="정산 상세내역"
			width="1400px"
			height="850px"

			@close="close"
		>
			<QuickSettlementDetail
				slot="modal-content"

				:item="item"
				:user="user"

				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
			></QuickSettlementDetail>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			:bottom="true"
			:top="true"

			title="선택 지급 확정"
			width="420px"
			content="선택 하신 내역을 지급 확정 처리하시겠습니까?"
			content_class="ptb-30"

			@close="close"
			@click="doUpdate('1')"
			@cancel="close"
		></Modal>
		<Modal
			:is_modal="is_modal4"
			:option="modal_option4"
			:bottom="true"
			:top="true"

			title="선택 지급 확정 취소"
			width="420px"
			content_class="ptb-30"

			@close="close"
			@click="doUpdate('0')"
			@cancel="close"
		>
			<div
				slot="modal-content"
			>선택 하신 내역을 지급 확정 취소 처리하시겠습니까?</div>
		</Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import QuickSettlementDetail from "@/view/Quick/QuickSettlementDetail";

export default {
	name: 'Settlement'
	,
	components: {QuickSettlementDetail, Modal, Excel, Search, Pagination},
	props: ['user', 'codes', 'Axios', 'date']
	,data: function(){
		return {
			program: {
				name: '배달비 정산 실행'
				,top: true
				,title: true
				,bottom: false
			}
			,datePicker: false
			,item_settlement: {
				calDate: ''
				, fromDate: ''
				, toDate: ''
				, type: this.$route.query.type ? this.$route.query.type : '1'
			}
			,search: {
				page: 1
				,size: 10
				,search_type: ''
				,search_value: ''
				,state: ''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 30, '-')
				,eDate: this.date.getToday('-')
				,type: '1'
				,search_date_type: 'month'
				,total_count: 0
				, distributorIdx: this.user.distributorIdx
				, branchIdx: this.user.branchIdx
				, agencyIdx: this.user.agencyIdx
				, resellerIdx: this.user.resellerIdx
			}

			,search_option: {
				sDate: true
				,eDate: true
				,search_type: [
					{ name: '가맹점명', column: 'name'}
				]
				,is_search_date_type: true
			}
			,is_excel: false
			,excel_data: {
				name: '정산 실행 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'no'}
					,{ key: 0, name: '매출 시작일', column: 'wDate'}
					,{ key: 0, name: '매출 종료일', column: 'name'}
					,{ key: 0, name: '정산 기준일', column: 'payment'}
					,{ key: 0, name: '가맹점', column: 'amount'}
					,{ key: 0, name: '결제건수', column: 'fee'}
					,{ key: 0, name: '결제금액', column: 'account'}
					,{ key: 0, name: '부가세포함', column: 'name'}
					,{ key: 0, name: '정산금액', column: 'name2'}
					,{ key: 0, name: '승인상태', column: 'rDate'}
					,{ key: 0, name: '지급여부', column: 'status_name'}
				]
				,content: null
			}
			,is_excel2: false
			,excel_data2: {
				name: '배달비 대량 이체 파일'
				,header: [
					{ key: 0, name: '은행코드', column: 'code'}
					,{ key: 0, name: '', column: 'wDate', text: ''}
					,{ key: 0, name: '계좌번호', column: 'account'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '상호', column: 'shopName'}
					,{ key: 0, name: '예금주명', column: 'depositor'}
					,{ key: 0, name: '', column: ''}
					,{ key: 0, name: '', column: 'name', text: ''}
					,{ key: 0, name: '', column: 'name2', text: ''}
					,{ key: 0, name: '', column: 'rDate', text: ''}
					,{ key: 0, name: '', column: 'status_name', text: ''}
					,{ key: 0, name: '비고', column: '', text: 'PG대금'}
				]
				,content: null
			}
			,items: []
			,items_detail: []
			,item: {}
			,is_modal: false
			,modal_option: {
				title: '정산 상태 변경'
				,content: ''
				,top: true
				,bottom: true
				,width: '380px'
				,content_class: 'ptb-30'
			}
			,is_modal2: false
			,modal_option2: {

			}
			,is_all: false
			,item_confirm: {
				status: ''
				, idx: []
			}
			,is_modal3: false
			,is_modal4: false
			,modal_option3: {

			}
			,modal_option4: {

			}
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.settlement_state.length; i++){
					if(item.state == self.codes.settlement_state[i].code){
						item.state_name = self.codes.settlement_state[i].name
						item.state_color = self.codes.settlement_state[i].color
						break
					}
				}

				return item
			})
		}
		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.idx)
				}
			})

			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')

				this.$set(this.search, 'startDate', this.search.sDate.replaceAll('-', '.'))
				this.$set(this.search, 'endDate', this.search.eDate.replaceAll('-', '.'))

				const result = await this.Axios({
					method: 'get'
					,url: 'quick/calculate'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.search.total_count = result.data.tableList.totalCount
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getSubData: async function(){
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'common/saleDate/' + this.item_settlement.calDate.replaceAll('-', '.') + '/' + this.item_settlement.type
				})

				if(result.success){
					this.item_settlement.fromDate = result.data.fromDate.replaceAll('.', '-')
					this.item_settlement.toDate = result.data.endDate.replaceAll('.', '-')
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message })
				}
			}catch(E){
				this.$emit('setNotify', { type: 'error', message: E })
			}
		}
		,toDetail: async function(item){
			this.item = item
			this.is_modal2 = true
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,search_value: ''
				,state: ''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 30, '-')
				,eDate: this.date.getToday('-')
				,type: '1'
				,search_date_type: 'month'
				,total_count: 0
				, distributorIdx: this.user.distributorIdx
				, branchIdx: this.user.branchIdx
				, agencyIdx: this.user.agencyIdx
				, resellerIdx: this.user.resellerIdx
			}

			this.getData()
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}
			//this.$emit('push', { name: this.$route.name, params: this.$route.params, query: this.search})
			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,toExcel2: async  function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'quick/calculate/bulk/excel'
					,data: this.search
				})

				if(result.success){
					this.excel_data2.content = result.data.data
					this.is_excel2 = true
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,close: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
			this.is_modal4 = false
		}
		,cancel: async function(){

		}
		,isConfirm: function(){
			if(this.item_confirm.status === ''){
				this.$emit('setNotify', { type: 'error', message: '처리상태를 선택하세요'})

			}else {

				let status_name = ''

				for(let i = 0; i < this.codes.settlementStatus.length; i++){
					if(this.item_confirm.status == this.codes.settlementStatus[i].code){
						status_name = this.codes.settlementStatus[i].code_name
					}
				}

				this.is_modal = true
				this.modal_option.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?'
			}
		}
		,doSettlement: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: 'quick/calculate'
					,data: {
						calDate: this.item_settlement.calDate.replaceAll('-', '.')
						, fromDate: this.item_settlement.fromDate.replaceAll('-', '.')
						, toDate: this.item_settlement.toDate.replaceAll('-', '.')
						, type: this.item_settlement.type
					}
				})

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: '정상적으로 처리되었습니다.'})
					this.getSearch()
					this.close()
					this.item_settlement= {
						calDate: ''
						, fromDate: ''
						, toDate: ''
						, type: this.$route.query.type ? this.$route.query.type : '1'
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toConfirm: function(){

			if(this.select_items.length <= 0) {
				this.$emit('setNotify', { type: 'error', message: '선택된 항목이 없습니다.'})
				return
			}
			this.is_modal3 = true
		}
		,toCancel: function(){

			if(this.select_items.length <= 0) {
				this.$emit('setNotify', { type: 'error', message: '선택된 항목이 없습니다.'})
				return
			}
			this.is_modal4 = true
		}
		,doUpdate: async function(state){

			if(this.select_items.length <= 0) {
				this.$emit('setNotify', { type: 'error', message: '선택된 항목이 없습니다.'})
				return
			}
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'quick/calculate'
					,data: {
						idxList: this.select_items
					}
				})

				console.log(state)

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: '정상적으로 처리되었습니다.'})
					this.getSearch()
					this.close()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'item_settlement.calDate': {
			handler: function () {
				if (this.item_settlement.calDate != '') {
					this.getSubData()
				}
			}
		}
		,'search.search_date_type': {
			handler: function(call){
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
.bg-icon-paper { background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-round-count { background: url('../../assets/images/icon/icon-round-count.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-calc { background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center; padding-left: 70px;}
</style>